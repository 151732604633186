/// <reference path='../_reference.ts' />
module Apex.Reporting.Controllers {
    export interface IPrCountScope extends angular.IScope {
        components: {
            warehousesGrid: Apex.Components.IApxGrid,
            AccName?: string,
            selected: any[]
        }
        events: {
            export: Function,
            refresh: Function,
            showBCodeHistoryModal: Function,
            printGrid: Function
        },
        warehousesList: any[],
        showRefreshInfo: boolean,
        testCount: any[];
        translateProvider: any;
    }

    export class PrCountController extends Core.Controller {
        public test: string;
        public static $inject = [Globals.scope, Globals.translate, 'reportingService', '$log'];
        constructor(private $scope: IPrCountScope, private $translate, private reportingService: Apex.Reporting.Services.Reporting, $log) {
            super(arguments, PrCountController.$inject);
            $scope.translateProvider = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            $scope.showRefreshInfo = true;
            $scope.components = {
                warehousesGrid: this.initWarehousesGrid(),
                selected: []

            }

            $scope.events = {
                export: () => {
                    $scope.components.warehousesGrid.grid.saveAsExcel()
                },

                refresh: this.refreshGrid,
                showBCodeHistoryModal: this.showBCodeHistoryModal,
                printGrid: this.printGrid
            };
            this.reportingService.GetWarehouses().then(r => {
                this.$scope.warehousesList = r.Result;
            });
        }



        printGrid = () => {
            var gridElement = this.$scope.components.warehousesGrid.grid.element,
                printableContent = '',
                win = window.open('', '', 'width=1000, height=700'),
                doc = win.document.open();

            var htmlStart =
                '<!DOCTYPE html>' +
                '<html>' +
                '<head>' +
                '<meta charset="utf-8" />' +
                '<title>Apex WebUI</title>' +
                '<link href="//kendo.cdn.telerik.com/' + kendo.version + '/styles/kendo.common.min.css" rel="stylesheet" /> ' +
                '<style>' +
                'html { font: 9pt sans-serif; }' +
                '.k-grid, .k-grid-content { height: auto !important; }' +
                '.k-grid-content { overflow: visible !important; }' +
                '.k-autocomplete, .k-autocomplete .k-input, .k-autocomplete.k-state-focused .k-input, .k-block, .k-button-group .k-tool, .k-calendar th, .k-colorpicker .k-i-arrow-s, .k-content, .k-dropdown-wrap, .k-dropdown-wrap .k-input, .k-dropdown-wrap.k-state-focused .k-input, .k-dropzone-active, .k-editable-area, .k-filter-row>th, .k-footer-template td, .k-gantt-toolbar .k-state-default, .k-grid .k-grouping-header, .k-grid td, .k-grid td.k-state-selected, .k-grid tr td, .k-grid-content-locked, .k-grid-footer, .k-grid-footer td, .k-grid-footer-locked, .k-grid-footer-wrap, .k-grid-header, .k-grid-header-locked, .k-grid-header-wrap, .k-group, .k-group-footer td, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-grouping-row td, .k-header, .k-input, .k-numeric-wrap.k-state-focused .k-input, .k-pager-refresh, .k-pager-wrap, .k-pager-wrap .k-link, .k-pager-wrap .k-textbox, .k-panel>.k-item>.k-link, .k-panelbar .k-content, .k-panelbar .k-panel, .k-panelbar>.k-item>.k-link, .k-picker-wrap.k-state-focused .k-input, .k-separator, .k-slider-track, .k-splitbar, .k-state-default, .k-state-default .k-select, .k-state-disabled, .k-textbox, .k-textbox>input, .k-tiles, .k-toolbar, .k-tooltip, .k-treemap-tile, .k-upload-files, .k-widget{border-color: #8a8d90!important;}' +
                'div.k-grid table {width: 100% !important; }' +
                '.k-grid .k-filter-row{display:none !important} .k-grid-header th { border-top: 1px solid; }' +
                '.k-grid-toolbar, .k-grid-pager > .k-link { display: none; }' +
                '.k-pager-wrap{display:none !important} .k-grid-pager{display:none !important}  .k-floatwrap{display:none !important}' +
                '</style>' +
                '</head>' +
                '<body>';

            var htmlEnd =
                '</body>' +
                '</html>';
            var gridHeader = gridElement.children('.k-grid-header');

            if (gridHeader[0]) {
                var thead = gridHeader.find('thead').clone().addClass('k-grid-header');
                printableContent = gridElement
                    .clone()
                    .children('.k-grid-header').remove()
                    .end()
                    .children('.k-grid-content')
                    .find('table')
                    .first()
                    .children('tbody').before(thead)
                    .end()
                    .end()
                    .end()
                    .end()[0].outerHTML;
            } else {
                printableContent = gridElement.clone()[0].outerHTML;
            }
            doc.write(htmlStart + printableContent + htmlEnd);
            setTimeout(() => {
                doc.close();
                win.print();
            }, 300);
        }

        refreshGrid = () => {
            this.reportingService.GetRemindersByWarehouse(this.$scope.components.AccName, null).then(result => {
                var convertedDatasource = [];
                _.forEach(result.Result, (item: any) => {
                    convertedDatasource.push({
                        BCode: item.GoodInfo.BCode,
                        InCode: item.GoodInfo.InCode,
                        Name: item.GoodInfo.Name,
                        RCount: item.RCount,
                        FxRCount: item.FxRCount,
                        Rezerved: item.Rezerved,
                        liveRCount: (item.RCount == null ? 0 : item.RCount)
                        + (item.FxRCount == null ? 0 : item.FxRCount)
                        - (item.Rezerved == null ? 0 : item.Rezerved),
                        Unit: item.GoodInfo.Unit,
                        AvgCost: item.AvgCost,
                        AvgBuyCost: item.AvgBuyCost,
                        SumPrice: item.AvgBuyCost * item.RCount,
                        SumAvgCost: item.AvgCost * item.RCount,
                        CategoryName: item.GoodInfo.Category.CategoryName,
                        ProducerName: item.GoodInfo.Producer.ProducerName,
                        ProdPPID: item.ProdPPID
                    })
                });
                this.$scope.components.warehousesGrid.dataSource.data(convertedDatasource);
               
            });
            this.$scope.showRefreshInfo = false;
        };
        initWarehousesGrid = () => {
            var grid: Components.IApxGrid =
                {
                    dataSource: this.getDatasource(),
                    columns: this.WarehousesGridColumns,
                    templates: this.WarehousesGridTemplates,
                    settings: {
                        sortable: Core.Kendo.SortType.single,
                        pageable: true,
                        editble: { disableEditRow: true },
                    },
                    gridEvents: [
                        this.getExportToExcelEvent(),
                    ],

                    parentScope: this.$scope
                };

            return grid;

        }
        getDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: 'BCode',
                            fields: {
                                BCode: { type: 'string' },
                                InCode: { type: 'string' },
                                Name: { type: "string" },
                                PartnerName: { type: "string" },
                                Unit: { type: "string" },
                                RCount: { type: "number" },
                                FxRCount: { type: "number" },
                                Rezerved: { type: "number" },
                                liveRCount: { type: "number" },
                                AvgCost: { type: "number" },
                                SumAvgCost: { type: "number" },
                                AvgBuyCost: { type: "number" },
                                SumPrice: { type: "number" },
                                CategoryName: { type: "string" },
                                ProducerName: { type: "string" }
                            }
                        }
                    },
                    pageSize: 100,
                    aggregate: [
                        { field: "RCount", aggregate: "sum" },
                        { field: "FxRCount", aggregate: "sum" },
                        { field: "SumPrice", aggregate: "sum" },
                        { field: "SumAvgCost", aggregate: "sum" }
                    ]
                });
        }
        showBCodeHistoryModal = (prod: any) => {
            var d1 = new Date();
            d1.setDate(1);
            d1.setHours(0, 0, 0, 0);
            var d2 = new Date();
            d2.setMonth(d1.getMonth() + 1);
            d2.setDate(0);
            d2.setHours(0, 0, 0, 0);
            var formData = {
                WhAcc: this.$scope.components.AccName,
                ProdId: prod.ProdPPID,
                SDate: d1,
                EDate: d2
            };
            
            Core.Utils.ModalForm.openModalForm("modal.bcode.history.html", Controllers.BCodeHistoryModalController.getName(), formData, Core.Utils.ModalSize.Large);

        }

        WarehousesGridTemplates: Array<Core.Interfaces.IGridColumnTemplate> =
        [
            { fieldName: 'BCode', template: '<div  ng-dblclick="options.parentScope.events.showBCodeHistoryModal(dataItem)" class="apx-selected">{{::dataItem.BCode}}</div>' },
            { fieldName: 'RCount', template: '<div ng-click="test(dataItem)"  class="apx-grid-cell-number">{{::dataItem.RCount| number}}</div>' },
            { fieldName: 'FxRCount', template: '<div class="apx-grid-cell-number">{{::dataItem.FxRCount| number}}</div>' },
            { fieldName: 'Rezerved', template: '<div class="apx-grid-cell-number">{{::dataItem.Rezerved| number}}</div>' },
            { fieldName: 'AvgCost', template: '<div class="apx-grid-cell-number">{{::dataItem.AvgCost| number:2}}</div>' },
            { fieldName: 'AvgBuyCost', template: '<div class="apx-grid-cell-number">{{::dataItem.AvgBuyCost| number:2}}</div>' },
            { fieldName: 'SumAvgCost', template: '<div class="apx-grid-cell-number">{{::dataItem.SumAvgCost| number:2}}</div>' },
            { fieldName: 'SumPrice', template: '<div class="apx-grid-cell-number">{{::dataItem.SumPrice| number:2}}</div>' }
        ];


        WarehousesGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "BCode", titleTranslated: "barcode", width: 100
            },
            {
                field: "InCode", titleTranslated: "inCode", width: 100
            },
            {
                field: "Name", titleTranslated: "caption", width: 300
            },
            {
                field: "Unit", titleTranslated: "ZE", width: 100
            },
            {
                field: "RCount", titleTranslated: "rest", width: 100,
                footerTemplate: '<div  style="float:right"><span translate=sum> </span> #= kendo.toString(data && data.RCount && data.RCount.sum ? parseFloat(data.RCount.sum).toFixed(2) : 0) #</div>'
            },
            {
                field: "FxRCount", titleTranslated: "fxRest", width: 100,
                footerTemplate: '<div  style="float:right"><span translate=sum> </span> #= kendo.toString(data && data.FxRCount && data.FxRCount.sum ? parseFloat(data.FxRCount.sum).toFixed(2) : 0) #</div>'
            },
            {
                field: "Rezerved", titleTranslated: "rezerved", width: 100
            },
            {
                field: "liveRCount", titleTranslated: "liveRCount", width: 100, format: Core.Kendo.Format.Number
            },
            {
                field: "AvgCost", titleTranslated: "costL", width: 100
            },
            {
                field: "SumAvgCost", titleTranslated: "sumAvgCost", width: 100, format: Core.Kendo.Format.Price4,
                footerTemplate: '<div  style="float:right"><span translate=sum> </span> #= kendo.toString(data && data.SumAvgCost && data.SumAvgCost.sum ? parseFloat(data.SumAvgCost.sum).toFixed(2) : 0) #</div>'
            },
            {
                field: "AvgBuyCost", titleTranslated: "lastCost", width: 100
            },
            {
                field: "SumPrice", titleTranslated: "sumPrice", width: 100, format: Core.Kendo.Format.Price4,
                footerTemplate: '<div  style="float:right"><span translate=sum> </span> #= kendo.toString(data && data.SumPrice && data.SumPrice.sum ? parseFloat(data.SumPrice.sum).toFixed(2) : 0) #</div>'
            },
            {
                field: "CategoryName", titleTranslated: "categoryName", width: 200
            },
            {
                field: "ProducerName", titleTranslated: "producer", width: 200
            }

        ];
        exportToExcelFunctionality = (e) => {
            var rows = e.workbook.sheets[0].rows;
            for (var ri = 0; ri < rows.length; ri++) {
                var row = rows[ri];
                if (row.type === "header") {
                    _.forEach(row.cells, (item: any) => {
                        item = this.initExcelCaptions(item);
                    });
                }
                
                if (row.type === "group-footer" || row.type === "footer") {
                    for (var ci = 0; ci < row.cells.length; ci++) {
                        var cell = row.cells[ci];
                        if (cell.value) {
                            cell.value = '';
                        }
                    }
                }
            }
        }

        getExportToExcelEvent(): Components.IEvent {
            var exportToExcelEventType: Components.EventType = Components.EventType.ExportToExcel;
            var exportEvent: Components.IEvent =
                {
                    eType: exportToExcelEventType,
                    eFunction: this.exportToExcelFunctionality
                };
            return exportEvent;
        }


        initExcelCaptions = (column: any): any => {
            if (column.value.includes("barcode")) {
                column.value = this.$scope.translateProvider.instant('barcode');
                return column;
            }
            if (column.value.includes("inCode")) {
                column.value = this.$scope.translateProvider.instant('inCode');
                return column;
            }
            if (column.value.includes("caption")) {
                column.value = this.$scope.translateProvider.instant('caption');
                return column;
            }
            if (column.value.includes("rest")) {
                column.value = this.$scope.translateProvider.instant('rest');
                return column;
            }
            if (column.value.includes("fxRest")) {
                column.value = this.$scope.translateProvider.instant('fxRest');
                return column;
            }
            if (column.value.includes("rezerved")) {
                column.value = this.$scope.translateProvider.instant('rezerved');
                return column;
            }
            if (column.value.includes("liveRCount")) {
                column.value = this.$scope.translateProvider.instant('liveRCount');
                return column;
            }
            if (column.value.includes("ZE")) {
                column.value = this.$scope.translateProvider.instant('ZE');
                return column;
            }
            if (column.value.includes("costL")) {
                column.value = this.$scope.translateProvider.instant('costL');
                return column;
            }
            if (column.value.includes("costL")) {
                column.value = this.$scope.translateProvider.instant('costL');
                return column;
            }
            if (column.value.includes("sumPrice")) {
                column.value = this.$scope.translateProvider.instant('sumPrice');
                return column;
            }
            if (column.value.includes("sumAvgCost")) {
                column.value = this.$scope.translateProvider.instant('sumAvgCost');
                return column;
            }
            if (column.value.includes("categoryName")) {
                column.value = this.$scope.translateProvider.instant('categoryName');
                return column;
            }
            if (column.value.includes("producer")) {
                column.value = this.$scope.translateProvider.instant('producer');
                return column;
            }

            return column;
        }
    }
}





