/// <reference path='../_reference.ts' />
module Apex.Reporting.Services {
    'use strict'
    export class Reporting extends Core.Service {
        goodsQueryUrl = '/Common/Dictionary/Goods/Query/';
        getDynamycDS(Unid: string): angular.IPromise<any> {
            return this.httpGet('/Core/Dynamic/DataSources/' + Unid);
        };
        getDynamycDSData(Unid: string): angular.IPromise<any> {
            return this.httpGet('/Core/Dynamic/DataSources/' + Unid);
        };
        GetRemindersByWarehouse(WarehouseAcc: string, data?: Date): angular.IPromise<any> {
            var params = {
                nm: 'WarehouseAcc={0}&Date={1}',
                pr: [WarehouseAcc, data]
            };
            return this.httpGet('/Warehousing/Reminders', params);
        };
        GetWarehouses(): angular.IPromise<any> {
            return this.httpGet('/Accounting/Accounts/Warehouses/');
        };
        GetBCodeHistoryById(prodId: string, whAcc: string, sdate?: Date, edate?: Date): angular.IPromise<any> {
            var params = {
                nm: 'Acc={0}&FromDate={1}&ToDate={2}',
                pr: [whAcc, sdate, edate]
            };
            return this.httpGet('/Warehousing/Goods/' + prodId + '/History', params);
        }
        getWBMOFDicionary(CID): angular.IPromise<any> {
            return this.httpGet('/RS/Waybills/MOFDicionary/' + CID);
        };
        getWayBill(Number: string): angular.IPromise<any> {
            return this.httpGet('/RS/Waybills/ByNumber/' + Number);
        };
        getIncomeDocTemplate(WaybillID): angular.IPromise<any> {
            return this.httpGet('/RS/Waybills/' + WaybillID + '/IncomeDocTemplate');
        }

        GetBCodeHistoryByBCode(barCode: string, whAcc: string, sdate?: Date, edate?: Date): angular.IPromise<any> {
            var params = {
                nm: 'Acc={0}&FromDate={1}&ToDate={2}',
                pr: [whAcc, sdate, edate]
            };
            return this.httpGet('/Warehousing/BarCodes/' + barCode + '/History', params);
        }
        GetGoodCostHistory(prodPPiD: string, sdate?: Date, edate?: Date): angular.IPromise<any> {
            var params = {
                nm: 'FromDate={0}&ToDate={1}',
                pr: [sdate, edate]
            };
            return this.httpGet('/Warehousing/Goods/' + prodPPiD + '/CostHistory', params);
        }
    }
}