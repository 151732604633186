/// <reference path='../_reference.ts' />
module Apex.Reporting.Controllers {
    export interface DynamicScope extends angular.IScope {        
        events: {
            refresh: Function
        },
        test: string;  
        dropDownControl: any;
        dateTimeControl: any;
        paramsList: Apex.Reporting.Interface.IParams[];
        htmlElement: HTMLElement;
        dataIsDownload?: boolean;
    }

    export class DynamicFormController extends Core.Controller {      
        public static $inject = [Globals.scope, Globals.translate, 'reportingService', '$log'];
        constructor(private $scope: DynamicScope, private $translate, private reportingService: Apex.Reporting.Services.Reporting, $log) {           
            super(arguments, DynamicFormController.$inject);   
            this.$scope.test = "ffgfrg";

            $scope.events = {
                refresh: this.getDinamicDs
            }; 
        }
         

      
        getDinamicDs = () => { 
                
            this.reportingService.getDynamycDS('009a9a3b73464770b30c5ef6371d14ee').then(result => {
                
                 this.$scope.paramsList = (result.Params);
                 this.$scope.dataIsDownload = true;               

             }); 
        }

    }
      
}
