/// <reference path='./_reference.ts' />
module Apex.Reporting {
    'use strict';

    var apxReporting = new Core.Module('reporting', ['apex.webUI.components', 'kendo.directives', 'bgDirectives'], Reporting);
    var routes: Array<Core.Interfaces.IRoute> =
        [
            {
                state: 'reporting',
                url: '^/reporting',
                template: '<ui-view/>',
                navigation: { caption: 'reporting', navigationClass: 'md md-local-print-shop' },
                childRoutes: [
                    {
                        state: 'prcount',
                        url: '/prcount',
                        controller: Apex.Reporting.Controllers.PrCountController.getName(),
                        templateUrl: 'prcountl.html',
                        navigation: {
                            caption: 'prcount'
                        }
                    },
                    {
                        state: 'bCodeHistory',
                        url: '/bCodeHistory',
                        controller: Apex.Reporting.Controllers.BCodeHistoryController.getName(),
                        templateUrl: 'bCodeHistory.html',
                        navigation: {
                            caption: 'prturnover'
                        }
                    }, 
                    {
                        state: 'costHistory',
                        url: '/costHistory',
                        controller: Apex.Reporting.Controllers.CostHistoryController.getName(),
                        templateUrl: 'costHistory.html',
                        navigation: {
                            caption: 'costHistory'
                        }
                    },
                    {
                        state: 'dynamicForms',
                        url: '/dynamicForms',
                        controller: Apex.Reporting.Controllers.DynamicFormController.getName(),
                        templateUrl: 'dynamicForms.html',
                        navigation: {
                            caption: 'dynamicForms'
                        }
                    }
                ]
            }
        ];
    apxReporting.regRoutes(routes, 'apex');
}