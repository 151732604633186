/// <reference path='../_reference.ts' />
module Apex.Reporting.Controllers {
    export interface IBCodeHistoryScope extends angular.IScope {
        components: {
            prodModel: any,
            warehousModel: any,
            ProdId?: string,
            Prod: any,
            historyList: any[],
            count_1: number;
            count_2: number;
            dateBetween: any;
        }
        events: {
            refresh: Function,
            editWB: Function,
            editBook: Function
        },
        WaybillId: string,
        mofDictionary: any,
        searchText: string,
        warehouses: any[],
        prodLookup: Components.IApxLookup,
        warehousesList: any[],
        showRefreshInfo: boolean
    }

    export class BCodeHistoryController extends Core.Controller {
        public static $inject = [Globals.scope, 'reportingService', '$log', Globals.translate, Globals.toaster];

        constructor(private $scope: IBCodeHistoryScope, private reportingService: Apex.Reporting.Services.Reporting, $log, private $translate, private toaster: any) {
            super(arguments, BCodeHistoryController.$inject);
            $scope.components = {
                prodModel: null,
                warehousModel: null,
                Prod: null,
                historyList: null,
                count_1: 0,
                count_2: 0,
                dateBetween: {}
            };
            $scope.events = {
                refresh: this.refreshGrid,
                editWB: this.editWB,
                editBook: this.editBook
            };
            $scope.WaybillId = "",
                $scope.mofDictionary = {
                    transportType: [],
                    waybillType: [],
                    waybillUnit: [],
                    carNumbers: []
                };
            $scope.searchText = "";
            $scope.prodLookup = this.initProdLookup();
            this.$scope.showRefreshInfo = true;

            this.reportingService.GetWarehouses().then(r => {
                this.$scope.warehousesList = r.Result;
            });
            this.getMofDicionary();
        }
        refreshGrid = () => {
            if (!this.$scope.components.prodModel) {
                this.toaster.pop('error', 'ყურადღება', 'აირჩიეთ საქონელი!');
                return;
            }
            this.$scope.components.count_1 = 0;
            this.$scope.components.count_2 = 0;
            this.reportingService.GetBCodeHistoryById(this.$scope.components.prodModel.ProdPPId, this.$scope.components.warehousModel, this.$scope.components.dateBetween.fromDate, this.$scope.components.dateBetween.toDate)
                .then(result => {
                    this.$scope.components.Prod = result.GoodInfo;
                    this.$scope.components.historyList = result.Data;
                    for (var i = 0; i < this.$scope.components.historyList.length; i++) {
                        if (this.$scope.components.historyList[i].RowType == 1) {
                            this.$scope.components.count_1 += Number(this.$scope.components.historyList[i].GCount);
                        }
                        if (this.$scope.components.historyList[i].RowType == 2) {
                            this.$scope.components.count_2 += Number(this.$scope.components.historyList[i].GCount);
                        }
                    }
                    

                });
            this.$scope.showRefreshInfo = false;
        };
        editWB = (WaybillNumber: string) => {
            kendo.ui.progress($(document.body), true);
            var formData: any = {
                WayBill: {},
                mofDictionary: this.$scope.mofDictionary,
                state: null
            };

            this.reportingService.getWayBill(WaybillNumber).then((result) => {
                if (result.Waybill !== undefined) {
                    formData.WayBill = result.Waybill;
                    Core.Utils.ModalForm.openModalForm("modal.waybill.html", Apex.RsService.Controllers.WaybillEdit.getName(), formData, Core.Utils.ModalSize.Medium);
                }
                kendo.ui.progress($(document.body), false);
            });
        };
        editBook = (DocID: any) => {
            kendo.ui.progress($(document.body), true);
            if (DocID) {
                Apex.Account.Utils.Documents.openDoc(DocID, false, () => { alert('დოკუმენტი შეინახა'); });
            }
            kendo.ui.progress($(document.body), false);
        };
        getMofDicionary() {
            this.reportingService.getWBMOFDicionary(1).then(result => {
                this.$scope.mofDictionary.transportType = result.Result;
                return "";
            });
            this.reportingService.getWBMOFDicionary(2).then(result => {
                this.$scope.mofDictionary.waybillType = result.Result;
                return "";
            });
            this.reportingService.getWBMOFDicionary(4).then(result => {
                this.$scope.mofDictionary.carNumbers = [];
                for (var i = 0; i < result.Result.length; i++) {
                    this.$scope.mofDictionary.carNumbers.push(result.Result[i].Name)
                }
                return "";
            });
        }
        initProdLookup = (): Components.IApxLookup => {
            var dsModel = {
                fields: {
                    'BCode': { type: 'string' },
                    'ProductsNu': { type: 'string' }
                }
            }
            var lookUp: Components.IApxLookup = {
                columns: [
                    { field: 'BCode', titleTranslated: 'barcode', width: 30 },
                    { field: 'ProductsNu', titleTranslated: 'goods', width: 80 }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.reportingService.goodsQueryUrl, dsModel),
                valueText: 'ProductsNu'
            };
            return lookUp;
        }
    }
}




