
module Apex.Reporting.Interface {
    "use strict";



    export interface IParams {
        Id?: string;
        Name?: string
        Description?: string;
        DataSourceId?: string;
        DbType?: string;
        Size?: number;
        QueryId?: string;
        Format: string;
        IsNullable?: boolean;
        OrderIndex?: number;
    }
    export interface GoodRemider {
        BCode?: string,
        InCode?: string,
        Name?: string,
        RCount?: number,
        FxRCount?: number,
        Rezerved?: number,
        liveRCount?: number,
        Unit?: string,
        AvgCost?: number,
        AvgBuyCost?: number,
        SumPrice?: number,
        SumAvgCost?: number,
        CategoryName?: string,
        ProducerName?: string
    }
}