/// <reference path='../_reference.ts' />
module Apex.Reporting.Controllers {
    export interface ICostHistoryScope extends angular.IScope {
        components: {
            warehousesGrid: Apex.Components.IApxGrid,
            prodModel: any,
            historyList: any[],
            dateBetween: any,
        }
        events: {
            refresh: Function,
            printGrid: Function
        },
        warehouses: any[],
        prodLookup: Components.IApxLookup,
        showRefreshInfo: boolean
    }

    export class CostHistoryController extends Core.Controller {
        public static $inject = [Globals.scope, 'reportingService', '$log', Globals.translate, Globals.toaster];

        constructor(private $scope: ICostHistoryScope, private reportingService: Apex.Reporting.Services.Reporting, $log, private $translate, private toaster: any) {
            super(arguments, CostHistoryController.$inject);
            $scope.components = {
                warehousesGrid: this.initWarehousesGrid(),
                prodModel: null,
                historyList: null,
                dateBetween: {}
            };
            $scope.events = {
                refresh: this.refreshGrid,
                printGrid: this.printGrid
            };
            $scope.prodLookup = this.initProdLookup();
            this.$scope.showRefreshInfo = true;
        }
        printGrid = () => {
            var gridElement = this.$scope.components.warehousesGrid.grid.element,
                printableContent = '',
                win = window.open('', '', 'width=1000, height=700'),
                doc = win.document.open();

            var htmlStart =
                '<!DOCTYPE html>' +
                '<html>' +
                '<head>' +
                '<meta charset="utf-8" />' +
                '<title>თვითღირებულების ისტორია</title>' +
                '<link href="//kendo.cdn.telerik.com/' + kendo.version + '/styles/kendo.common.min.css" rel="stylesheet" /> ' +
                '<style>' +
                'html { font: 9pt sans-serif; }' +
                '.k-grid, .k-grid-content { height: auto !important; }' +
                '.k-grid-content { overflow: visible !important; }' +
                '.k-autocomplete, .k-autocomplete .k-input, .k-autocomplete.k-state-focused .k-input, .k-block, .k-button-group .k-tool, .k-calendar th, .k-colorpicker .k-i-arrow-s, .k-content, .k-dropdown-wrap, .k-dropdown-wrap .k-input, .k-dropdown-wrap.k-state-focused .k-input, .k-dropzone-active, .k-editable-area, .k-filter-row>th, .k-footer-template td, .k-gantt-toolbar .k-state-default, .k-grid .k-grouping-header, .k-grid td, .k-grid td.k-state-selected, .k-grid tr td, .k-grid-content-locked, .k-grid-footer, .k-grid-footer td, .k-grid-footer-locked, .k-grid-footer-wrap, .k-grid-header, .k-grid-header-locked, .k-grid-header-wrap, .k-group, .k-group-footer td, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-grouping-row td, .k-header, .k-input, .k-numeric-wrap.k-state-focused .k-input, .k-pager-refresh, .k-pager-wrap, .k-pager-wrap .k-link, .k-pager-wrap .k-textbox, .k-panel>.k-item>.k-link, .k-panelbar .k-content, .k-panelbar .k-panel, .k-panelbar>.k-item>.k-link, .k-picker-wrap.k-state-focused .k-input, .k-separator, .k-slider-track, .k-splitbar, .k-state-default, .k-state-default .k-select, .k-state-disabled, .k-textbox, .k-textbox>input, .k-tiles, .k-toolbar, .k-tooltip, .k-treemap-tile, .k-upload-files, .k-widget{border-color: #8a8d90!important;}' +
                'div.k-grid table {width: 100% !important; }' +
                '.k-grid .k-filter-row{display:none !important} .k-grid-header th { border-top: 1px solid; }' +
                '.k-grid-toolbar, .k-grid-pager > .k-link { display: none; }' +
                '.k-pager-wrap{display:none !important} .k-grid-pager{display:none !important}  .k-floatwrap{display:none !important}' +
                '</style>' +
                '</head>' +
                '<body>';

            var htmlEnd =
                '</body>' +
                '</html>';
            var gridHeader = gridElement.children('.k-grid-header');

            if (gridHeader[0]) {
                var thead = gridHeader.find('thead').clone().addClass('k-grid-header');
                printableContent = gridElement
                    .clone()
                    .children('.k-grid-header').remove()
                    .end()
                    .children('.k-grid-content')
                    .find('table')
                    .first()
                    .children('tbody').before(thead)
                    .end()
                    .end()
                    .end()
                    .end()[0].outerHTML;
            } else {
                printableContent = gridElement.clone()[0].outerHTML;
            }
            doc.write(htmlStart + printableContent + htmlEnd);
            setTimeout(() => {
                doc.close();
                win.print();
            }, 300);
        }
        refreshGrid = () => {
           

            if (!this.$scope.components.prodModel) {
                this.toaster.pop('error', 'ყურადღება', 'აირჩიეთ საქონელი!');
                return;
            }
            this.reportingService.GetGoodCostHistory(this.$scope.components.prodModel.ProdPPId, this.$scope.components.dateBetween.fromDate, this.$scope.components.dateBetween.toDate).then(res => {
                
                this.$scope.components.warehousesGrid.dataSource.data(res.Data);
            });
            this.$scope.showRefreshInfo = false;
        };
        initWarehousesGrid = () => {
            var grid: Components.IApxGrid =
                {
                    dataSource: this.getDatasource(),
                    columns: this.WarehousesGridColumns,
                    templates: this.WarehousesGridTemplates,
                    settings: {
                        sortable: Core.Kendo.SortType.single,
                        pageable: true,
                        editble: { disableEditRow: true },
                    },
                    gridEvents: [
                        // this.getExportToExcelEvent(),
                    ],

                    parentScope: this.$scope
                };

            return grid;

        }
        getDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: 'BCode',
                            fields: {
                                OperID: { type: 'string' },
                                DDate: { type: 'date' },
                                NumberIn: { type: "string" },
                                NumberOut: { type: "string" },
                                ICount: { type: "number" },
                                UnitCostG: { type: "number" },
                                UnitExpG: { type: "number" },
                                UnitFullCostG: { type: "number" }
                            }
                        }
                    },
                    pageSize: 100,
                    aggregate: [
                        { field: "ICount", aggregate: "sum" },
                        { field: "UnitCostG", aggregate: "average" },
                        { field: "UnitExpG", aggregate: "average" },
                        { field: "UnitFullCostG", aggregate: "average" }
                    ]
                });
        }
        WarehousesGridTemplates: Array<Core.Interfaces.IGridColumnTemplate> =
        [
            { fieldName: 'ICount', template: '<div class="apx-grid-cell-number">{{::dataItem.UnitCostG| number}}</div>' },
            { fieldName: 'UnitCostG', template: '<div class="apx-grid-cell-number">{{::dataItem.UnitCostG| number:2}}</div>' },
            { fieldName: 'UnitExpG', template: '<div class="apx-grid-cell-number">{{::dataItem.UnitExpG| number:2}}</div>' },
            { fieldName: 'UnitFullCostG', template: '<div class="apx-grid-cell-number">{{::dataItem.UnitFullCostG| number:2}}</div>' }
        ];


        WarehousesGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "OperID", titleTranslated: "opCode", width: 100
            },
            {
                field: "DDate", titleTranslated: "DATE", width: 100, format: Core.Kendo.Format.Date
            },
            {
                field: "NumberIn", titleTranslated: "numberin", width: 100
            },
            {
                field: "NumberOut", titleTranslated: "numberout", width: 100
            },
            {
                field: "ICount", titleTranslated: "Count", width: 100,
                footerTemplate: '<div  style="float:right"><span translate=sum> </span> #= kendo.toString(data && data.ICount && data.ICount.sum ? parseFloat(data.ICount.sum).toFixed(2) : 0) #</div>'
            },
            {
                field: "UnitCostG", titleTranslated: "UnitCostG", width: 100,
                footerTemplate: '<div  style="float:right"><span translate=avg> </span> #= kendo.toString(data && data.UnitCostG && data.UnitCostG.average ? parseFloat(data.UnitCostG.average).toFixed(2) : 0) #</div>'
            },
            {
                field: "UnitExpG", titleTranslated: "UnitExpG", width: 100,
                footerTemplate: '<div  style="float:right"><span translate=avg> </span> #= kendo.toString(data && data.UnitExpG && data.UnitExpG.average ? parseFloat(data.UnitExpG.average).toFixed(2) : 0) #</div>'
            },
            {
                field: "UnitFullCostG", titleTranslated: "UnitFullCostG", width: 100,
                footerTemplate: '<div  style="float:right"><span translate=avg> </span> #= kendo.toString(data && data.UnitFullCostG && data.UnitFullCostG.average ? parseFloat(data.UnitFullCostG.average).toFixed(2) : 0) #</div>'
            }
        ];
        initProdLookup = (): Components.IApxLookup => {
            var dsModel = {
                fields: {
                    'BCode': { type: 'string' },
                    'ProductsNu': { type: 'string' }
                }
            }
            var lookUp: Components.IApxLookup = {
                columns: [
                    { field: 'BCode', titleTranslated: 'barcode', width: 30 },
                    { field: 'ProductsNu', titleTranslated: 'goods', width: 80 }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.reportingService.goodsQueryUrl, dsModel),
                valueText: 'ProductsNu'
            };
            return lookUp;
        }
    }
}




