/// <reference path='../_reference.ts' />
module Apex.Reporting.Controllers {
    export interface BCodeHistoryModalScope extends angular.IScope {
        components: {
            WhAcc?: string,
            ProdId?: string,
            Prod: any,
            SDate: Date,
            EDate: Date,
            historyList: any[],
            count_1: number;
            count_2: number;
        }
        events: {
            refresh: Function,
            editWB: Function,
            editBook: Function,
            closeForm: Function
        },
        mofDictionary: any,
        dateBetween: any;
        warehouses: any[],
        showRefreshInfo: boolean,
        title: string;
    }

    export class BCodeHistoryModalController extends Core.Controller {
        public static $inject = [Globals.scope, 'reportingService', '$log', Globals.dialogs, Globals.translate, Globals.toaster, 'data', Globals.modalInstance];
        $uibModalInstance: any;
        constructor(private $scope: BCodeHistoryModalScope, private reportingService: Apex.Reporting.Services.Reporting, $log, dialogs: any, private $translate, toaster: any, private data, modalInstance) {
            super(arguments, BCodeHistoryModalController.$inject);
            this.$uibModalInstance = modalInstance;
            $scope.components = {
                WhAcc: data.WhAcc,
                ProdId: data.ProdId,
                Prod: null,
                SDate: data.SDate,
                EDate: data.EDate,
                historyList: null,
                count_1: 0,
                count_2: 0

            };
            $scope.events = {
                refresh: this.refreshGrid,
                editWB: this.editWB,
                editBook: this.editBook,
                closeForm: this.closeForm
            };
            $scope.mofDictionary = {
                transportType: [],
                waybillType: [],
                waybillUnit: [],
                carNumbers: []
            };
            $scope.title = "საქონლის ისტორია";
            $scope.dateBetween = {
                fromDate: this.$scope.components.SDate,
                toDate: this.$scope.components.EDate
            };
            $scope.events.refresh();
            this.getMofDicionary();
        }

        refreshGrid = () => {
            this.$scope.components.count_1 = 0;
            this.$scope.components.count_2 = 0;
            var d1 = this.$scope.components.SDate;
            var d2 = this.$scope.components.EDate;
            this.reportingService.GetBCodeHistoryById(this.$scope.components.ProdId, this.$scope.components.WhAcc, this.$scope.dateBetween.fromDate, this.$scope.dateBetween.toDate)
                .then(result => {
                    this.$scope.components.Prod = result.GoodInfo;
                    this.$scope.components.historyList = result.Data;
                    for (var i = 0; i < this.$scope.components.historyList.length; i++) {
                        if (this.$scope.components.historyList[i].RowType == 1) {
                            this.$scope.components.count_1 += Number(this.$scope.components.historyList[i].GCount);
                        }
                        if (this.$scope.components.historyList[i].RowType == 2) {
                            this.$scope.components.count_2 += Number(this.$scope.components.historyList[i].GCount);
                        }
                    }
                });
            this.$scope.showRefreshInfo = false;
        };

        editWB = (WaybillNumber: string) => {
            kendo.ui.progress($(document.body), true);
            var formData: any = {
                WayBill: {},
                mofDictionary: this.$scope.mofDictionary,
                state: null
            };

            this.reportingService.getWayBill(WaybillNumber).then((result) => {
                if (result.Waybill !== undefined) {
                    formData.WayBill = result.Waybill;
                    Core.Utils.ModalForm.openModalForm("mymodal.waybill.html", Apex.RsService.Controllers.WaybillEdit.getName(), formData, Core.Utils.ModalSize.Medium);
                }
                kendo.ui.progress($(document.body), false);
            });
        };
        editBook = (DocID: any) => {
            kendo.ui.progress($(document.body), true);
            if (DocID) {
                Apex.Account.Utils.Documents.openDoc(DocID, false, () => { alert('დოკუმენტი შეინახა'); });
            }
            kendo.ui.progress($(document.body), false);
        };
        getMofDicionary() {
            this.reportingService.getWBMOFDicionary(1).then(result => {
                this.$scope.mofDictionary.transportType = result.Result;
                return "";
            });
            this.reportingService.getWBMOFDicionary(2).then(result => {
                this.$scope.mofDictionary.waybillType = result.Result;
                return "";
            });
            this.reportingService.getWBMOFDicionary(4).then(result => {
                this.$scope.mofDictionary.carNumbers = [];
                for (var i = 0; i < result.Result.length; i++) {
                    this.$scope.mofDictionary.carNumbers.push(result.Result[i].Name)
                }
                return "";
            });
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }
    }
}





